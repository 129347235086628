
import LoginHeaderLoginToUpgrade from "@/components/views/loggedOut/headerComponents/login/LoginHeaderLoginToUpgrade.vue";
import LoginHeaderRegistrationComplete from "@/components/views/loggedOut/headerComponents/login/LoginHeaderRegistrationComplete.vue";
import LoginHeaderRegular from "@/components/views/loggedOut/headerComponents/login/LoginHeaderRegular.vue";
import OrganizationSelect from "@/components/views/loggedOut/OrganizationSelect.vue";
import { GoogleUser, LoginHeaderType, OtpAuthenticationData } from "@/types/localtypes";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import GoogleLogin from "@/components/common/GoogleLogin.vue";
import {
  isNeedOtpLoginResponse,
  isRestClientError,
  LoginRequest,
  LoginResponse,
  NeedOtpLoginResponse,
  RegularLoginResponse,
  SsoLoginRequest,
  storeErrorsMapping,
  UserDTO,
} from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

declare var window: any;

@Component({
  name: "Login",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    OrganizationSelect,
    GoogleLogin,
  },
})
export default class Login extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Action("setUser", { namespace: "user" })
  setUser!: (user: UserDTO) => Promise<void>;
  @Getter("loginHeaderType", { namespace: "dynamicHeader" })
  loginHeaderType!: LoginHeaderType;
  @Action("setOtpAuthenticationData", { namespace: "authentication" })
  setOtpAuthenticationData!: (otpAuthenticationData: OtpAuthenticationData) => Promise<void>;

  loginRequest: LoginRequest = { userIdentifier: "", password: "" };
  ssoLoginRequest: SsoLoginRequest | null = null;
  innerError = "";
  authFilledByBrowser = false;
  keyDownCounter = 0;
  timeoutEvent: number | undefined = undefined;

  get loginHeaderComponent() {
    switch (this.loginHeaderType) {
      case "loginToUpgrade":
        return LoginHeaderLoginToUpgrade;
      case "registrationComplete":
        return LoginHeaderRegistrationComplete;
      case "regular":
      default:
        return LoginHeaderRegular;
    }
  }

  get userNameLabelIcon() {
    return {
      iconClass: "icon-art-notif-icon",
      tooltipText: {
        placement: "right",
        content:
          "This is the verified system administrator username or email address associated with your JFrog account. Refer to your welcome email.",
      },
    };
  }

  get shouldDisableSubmitBtn() {
    return (
      this.errors.any() ||
      (!this.authFilledByBrowser && (!this.loginRequest.userIdentifier || !this.loginRequest.password))
    );
  }

  toForgotPassword() {
    this.$router.push({ path: "/forgot-password" });
  }

  toRegisterNow() {
    this.$router.push({ path: "/register-now" });
  }

  getOtpAuthenticatorRoutePath(authenticator: NeedOtpLoginResponse["authenticator"]) {
    switch (authenticator) {
      case "GOOGLE":
        return "/google-authenticator";
      default:
        return null;
    }
  }

  onSignIn(googleUser: GoogleUser) {
    this.errors.clear();
    var profile = googleUser.getBasicProfile();
    this.ssoLoginRequest = {
      userIdentifier: profile.getEmail(),
      ssoToken: googleUser.getAuthResponse().id_token,
      ssoOrigin: "google",
    };
    this.doLogin();
  }

  onFailure(error: string) {
    this.$log.error(error);
  }

  async toLoginAuthenticator(needOtpLoginResponse: NeedOtpLoginResponse) {
    const authenticator = needOtpLoginResponse.authenticator;
    const nextRouterPath = this.getOtpAuthenticatorRoutePath(authenticator);

    if (!nextRouterPath) {
      this.$log.error(`Unknown MFA authenticator '${authenticator}'`);
      this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong_login });
      return;
    }

    await this.setOtpAuthenticationData({
      otpTokenKeyLink: needOtpLoginResponse.otpTokenKeyLink,
      authenticator: needOtpLoginResponse.authenticator,
      fromSSO: false,
    });
    await this.$router.push({ path: nextRouterPath });
  }

  onEnterPress() {
    this.doLogin();
  }
  get displayInnerError() {
    return this.innerError;
  }

  onKeyDown() {
    this.clearInnerError();
    this.clearAllNotifications();
  }

  // when user comes other page and we want him to see last message,
  // so we check if last keydown is human or autofill of the browser.
  clearAllNotifications() {
    this.keyDownCounter++;
    if (this.timeoutEvent) {
      return;
    }
    this.timeoutEvent = setTimeout(() => {
      if (this.keyDownCounter === 1) {
        this.$jfNotification.clearAll();
      } else {
        this.authFilledByBrowser = true;
      }
      this.keyDownCounter = 0;
      clearTimeout(this.timeoutEvent);
      this.timeoutEvent = 0;
    }, 10);
  }

  async doLogin() {
    try {
      if ((!window.gapi || !window.gapi.auth2) && !(await this.$validator.validateAll())) {
        return;
      }
      this.showAppLoadingMask();
      const loginResponse: LoginResponse | null = this.ssoLoginRequest
        ? await this.$jfUsers.loginSso(this.ssoLoginRequest)
        : await this.$jfUsers.login(this.loginRequest);
      this.hideAppLoadingMask();
      if (!loginResponse) {
        return;
      }
      if (isNeedOtpLoginResponse(loginResponse)) {
        this.toLoginAuthenticator(loginResponse);
      } else {
        this.loginSuccess(loginResponse);
      }
    } catch (e) {
      if (window.gapi && window.gapi.auth2) {
        this.ssoLoginRequest = null;
        window.gapi.auth2.getAuthInstance().disconnect();
      }

      this.$log.error(e);
      this.hideAppLoadingMask();
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      if (isRestClientError(e) && e.httpBody) {
        // Displaying Store message for 2 specific cases : user not active or blocked.
        // If it's not these cases but we got a 404 we display a generic "not found" error
        // If we are not in these cases we display the message we got (e.httpStatusText)
        if (
          e.httpBody.result === storeErrorsMapping.login.userBlocked ||
          e.httpBody.result === storeErrorsMapping.login.userNotActive ||
          e.httpBody.result === storeErrorsMapping.login.maxFailedLoginAttemptsReached ||
          e.httpBody.result === storeErrorsMapping.login.multiActiveUsersForSameEmail
        ) {
          errorMessage = this.$jfMessages.generateMailLink(e.httpBody.message);
        } else {
          if (e.httpStatus === 404) {
            this.innerError = this.$jfMessages.login_user_not_found;
            return;
          } else if (e.httpStatus === 429) {
            errorMessage = this.$jfMessages.too_many_requests;
          }
        }
      }

      this.$jfNotification.error({ text: errorMessage || e });
    }
  }

  async loginSuccess(loginResponse: RegularLoginResponse) {
    this.globalBus.$emit("shouldHandleLoginSuccess", loginResponse);
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  created() {
    if (process.env.NODE_ENV != "production" && process.env.VUE_APP_DEV_USERNAME && process.env.VUE_APP_DEV_PASSWORD) {
      this.loginRequest = {
        userIdentifier: process.env.VUE_APP_DEV_USERNAME,
        password: process.env.VUE_APP_DEV_PASSWORD,
      };
    }
  }

  mounted() {
    this.globalBus.$emit("shouldCheckLastLoginFlag");
    ((this.$refs["username-input"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }

  clearInnerError() {
    this.innerError = "";
  }
}
