import { render, staticRenderFns } from "./LoginHeaderLoginToUpgrade.vue?vue&type=template&id=5b0dade0&scoped=true&"
import script from "./LoginHeaderLoginToUpgrade.vue?vue&type=script&lang=ts&"
export * from "./LoginHeaderLoginToUpgrade.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0dade0",
  null
  
)

export default component.exports